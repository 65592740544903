const LEGACY_QUERIES = ['GEOIP'];

export const customFetch: typeof fetch = (input, init) => {
	let url = input;

	/** Dirty Quickfix: avoid race condition where 2 sessions and checkforms
	 * are created as both getUserData and authConfig are sent in // */
	if (typeof input === 'string') {
		const inputAsUrl = new URL(input);
		const search = typeof location !== 'undefined' ? location.search : '';
		const currentQuery = new URLSearchParams(search);

		if (search.length > 0) {
			LEGACY_QUERIES.forEach((param) => {
				const value = currentQuery.get(param);
				if (value) {
					inputAsUrl.searchParams.append(param, value);
				}
			});
			url = inputAsUrl.href;
		}
	}
	return fetch(url, init);
};
